<template>
  <div class="home">
    <main>
      <!-- Conteúdo Principal -->
      <section id="content" class="terms-section">
        <h1>Termos de Serviço</h1>
        <p>
          Os termos de serviço estabelecem as regras para o acesso e utilização
          do Gerar CPF CNPJ, incluindo qualquer conteúdo, funcionalidade e
          serviços oferecidos pelo Site.
        </p>
        <p>
          Certifique-se de ler os termos de serviço com atenção antes de
          utilizar o Site. O uso implica aceitação destes termos.
        </p>

        <h2 id="responsibility">Isenção de Responsabilidade</h2>
        <p>
          O serviço é gratuito e não temos como garantir que será ininterrupto,
          seguro ou livre de erros. Não nos responsabilizamos por danos
          decorrentes do uso.
        </p>

        <h2>Responsabilidade do Usuário</h2>
        <p>
          Ao usar o Site, você assume responsabilidade pessoal pelas suas ações
          e consequências.
        </p>

        <h2 id="warranty">Sem Garantias</h2>
        <p>
          O serviço é fornecido "como está" e não oferece garantias implícitas
          ou explícitas sobre o funcionamento.
        </p>

        <h2 id="limitation">Limitação de Responsabilidade</h2>
        <p>
          Não somos responsáveis por perdas diretas ou indiretas, incluindo
          perda de dados, interrupção de negócios ou lucros cessantes.
        </p>

        <h2 id="updates">Atualizações dos Termos</h2>
        <p>
          Reservamo-nos o direito de atualizar os termos de uso a qualquer
          momento. A versão mais atual estará sempre disponível nesta página.
        </p>
      </section>
    </main>

    <!-- Footer -->
    <FooterComponent>
      <div class="text-center mt-2 text-white small">
        Página atualizada em {{ currentYear }}
      </div>
    </FooterComponent>
  </div>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "HomeView",
  components: {
    FooterComponent,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<!-- Estilização CSS -->
<style scoped>
.home {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f9fafb;
  padding: 20px;
}

h1,
h2 {
  color: #2c3e50;
  margin-top: 20px;
}

p {
  color: #444;
  margin-bottom: 15px;
}

.anchor-menu {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;

  .nav-link {
    color: #2563eb;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
      color: #174ea6;
    }
  }
}

.terms-section {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
