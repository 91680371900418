<template>
  <!-- Footer fixado no rodapé -->
  <div class="pt-5 fixed-bottom">
    <footer class="text-center text-lg-start footer-bg">
      <!-- Botão central -->
      <div class="container d-flex justify-content-center py-4">
        <a
          href="https://robertalmeida.vercel.app/"
          target="_blank"
          class="text-decoration-none"
        >
          <button
            type="button"
            class="btn btn-light shadow-sm btn-circle"
            aria-label="Visite meu portfólio"
          >
            <i class="fas fa-user fa-lg text-primary"></i>
          </button>
        </a>
      </div>

      <div class="container d-flex justify-content-center py-4">
        <router-link
          to="/"
          class="btn btn-light shadow-sm btn-circle"
          aria-label="Home"
        >
          <i class="fas fa-home fa-lg text-primary"></i>
        </router-link>
        <router-link
          to="/privacidade"
          class="btn btn-light shadow-sm btn-circle mx-2"
          aria-label="Privacidade"
        >
          <i class="fas fa-shield-alt fa-lg text-primary"></i>
        </router-link>
        <router-link
          to="/termos"
          class="btn btn-light shadow-sm btn-circle"
          aria-label="Termos"
        >
          <i class="fas fa-file-alt fa-lg text-primary"></i>
        </router-link>
      </div>

      <!-- Texto de copyright -->
      <div class="text-center text-white p-2 copyright-bg">
        © {{ year }} gerarcpfcnpj.com.br — Ferramenta para gerar dados fictícios
        com segurança e praticidade.
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {
    msg: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<!-- Estilização CSS -->
<style scoped>
.footer-bg {
  background-color: #3b82f6; /* Azul moderno */
}

.copyright-bg {
  background-color: rgba(0, 0, 0, 0.2);
}

.btn-circle {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    background-color: #e0f2fe;
  }
}

.btn-light {
  background-color: #ffffff;
  border: none;
  color: #3b82f6;
}

i {
  transition: color 0.3s ease;
}

.btn:hover i {
  color: #2563eb; /* Azul mais escuro ao passar o mouse */
}
</style>
