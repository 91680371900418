<template>
  <div class="home">
    <main>
      <!-- Conteúdo principal -->
      <section id="content" class="privacy-section">
        <h1>Política de Privacidade</h1>
        <p>
          A política de privacidade explica quais dados pessoais coletamos e de
          que forma eles são usados. Se você não concordar, não deve acessar ou
          usar o Site.
        </p>

        <h2 id="info-collected">Informações Coletadas</h2>
        <p>
          O Site coleta informações pessoais, como email, endereço IP e hábitos
          de navegação.
        </p>

        <h2 id="info-use">Uso das Informações</h2>
        <p>
          Suas informações são usadas para melhorar nossos serviços e enviar
          comunicações relevantes.
        </p>

        <h2 id="security">Segurança</h2>
        <p>
          Adotamos medidas de segurança para proteger seus dados, mas não
          garantimos segurança total.
        </p>

        <h2 id="cookies">Cookies e Publicidade</h2>
        <p>
          Usamos cookies e serviços de anúncios de terceiros. É possível
          gerenciar cookies nas configurações do navegador.
        </p>

        <h2 id="updates">Alterações na Política</h2>
        <p>
          Reservamos o direito de alterar a política a qualquer momento,
          publicando as atualizações nesta página.
        </p>
      </section>
    </main>

    <!-- Footer -->
    <FooterComponent>
      <div class="footer-text">Última atualização: {{ currentYear }}</div>
    </FooterComponent>
  </div>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "HomeView",
  components: {
    FooterComponent,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.home {
  font-family: Arial, sans-serif;
  background-color: #f9fafb;
  color: #333;
  padding: 20px;

  h1,
  h2 {
    color: #2c3e50;
  }

  p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
}

.anchor-menu {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;

  .nav-link {
    text-decoration: none;
    color: #2563eb;
    font-weight: bold;

    &:hover {
      color: #174ea6;
      text-decoration: underline;
    }
  }
}

.privacy-section {
  background: #ffffff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.footer-text {
  text-align: center;
  font-size: 0.9em;
  color: #555;
  margin-top: 20px;
}
</style>
