<template>
  <div class="container py-5">
    <!-- Título principal -->
    <div class="text-center mb-4">
      <h2 class="text-primary fw-bold">
        Gerador de CPF/CNPJ <i class="fa fa-check-circle"></i>
      </h2>
      <p class="text-muted">
        Gere facilmente documentos válidos para testes e aplicações.
      </p>
    </div>

    <!-- Alertas -->
    <div v-if="copy" class="alert alert-success fade show" role="alert">
      🎉 Copiado com sucesso!
    </div>
    <div v-if="error" class="alert alert-danger fade show" role="alert">
      ⚠️ Selecione uma opção antes de gerar.
    </div>

    <!-- Card principal -->
    <div class="card shadow-lg border-0 rounded-4">
      <div class="card-body p-4">
        <h4 class="card-title text-center mb-3 fw-semibold">
          Selecione o tipo de documento
        </h4>
        <!-- Seleção de tipo -->
        <div class="mb-4">
          <select
            class="form-select form-select-lg"
            v-model="type"
            aria-label="Selecione o tipo"
          >
            <option value="0">Escolha uma opção...</option>
            <option value="1">CPF</option>
            <option value="2">CNPJ</option>
          </select>
        </div>

        <!-- Checkbox de formatação -->
        <div class="d-flex justify-content-center gap-3 mb-4">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="format"
              @change="formats(true)"
              id="formatCheck"
            />
            <label class="form-check-label" for="formatCheck">
              Com formatação
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="not_format"
              @change="formats(false)"
              id="noFormatCheck"
            />
            <label class="form-check-label" for="noFormatCheck">
              Sem formatação
            </label>
          </div>
        </div>

        <!-- Botão de gerar -->
        <div class="text-center mb-3">
          <button
            class="btn btn-primary btn-lg px-5 rounded-pill"
            @click="gerar"
          >
            🚀 Gerar Documento
          </button>
        </div>

        <!-- Campo do resultado -->
        <div v-if="gerado" class="text-center mt-4">
          <div class="input-group shadow-sm rounded-pill">
            <input
              type="text"
              class="form-control form-control-lg border-0 text-center"
              v-model="gerado"
              readonly
            />
            <button
              class="btn btn-outline-secondary rounded-pill"
              @click="copiarTexto"
            >
              <i class="fa fa-copy"></i>
            </button>
          </div>
          <button class="btn btn-success mt-3 px-4 rounded-pill" @click="gerar">
            🔄 Gerar Outro
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainComponent",
  data() {
    return {
      type: "0",
      gerado: "",
      error: false,
      format: true,
      not_format: false,
      copy: false,
    };
  },
  methods: {
    gerar() {
      if (this.type === "1") {
        this.gerado = this.gerarCPF();
      } else if (this.type === "2") {
        this.gerado = this.gerarCNPJ();
      } else {
        this.isError();
      }
    },

    formats(isChecked: boolean) {
      if (isChecked) {
        this.format = true;
        this.not_format = false;
      } else {
        this.format = false;
        this.not_format = true;
      }
    },
    isError() {
      this.error = true;
      this.gerado = "";

      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    gerarCNPJ(): string {
      const n = 9;
      let n1 = this.getRandomInt(0, n);
      let n2 = this.getRandomInt(0, n);
      let n3 = this.getRandomInt(0, n);
      let n4 = this.getRandomInt(0, n);
      let n5 = this.getRandomInt(0, n);
      let n6 = this.getRandomInt(0, n);
      let n7 = this.getRandomInt(0, n);
      let n8 = this.getRandomInt(0, n);
      let n9 = 0; // Inicialmente setado para zero
      let n10 = 0; // Inicialmente setado para zero
      let n11 = 0; // Inicialmente setado para zero
      let n12 = 1; // Inicialmente setado para um

      let d1 =
        n12 * 2 +
        n11 * 3 +
        n10 * 4 +
        n9 * 5 +
        n8 * 6 +
        n7 * 7 +
        n6 * 8 +
        n5 * 9 +
        n4 * 2 +
        n3 * 3 +
        n2 * 4 +
        n1 * 5;
      d1 = 11 - (d1 % 11);
      if (d1 >= 10) d1 = 0;

      let d2 =
        d1 * 2 +
        n12 * 3 +
        n11 * 4 +
        n10 * 5 +
        n9 * 6 +
        n8 * 7 +
        n7 * 8 +
        n6 * 9 +
        n5 * 2 +
        n4 * 3 +
        n3 * 4 +
        n2 * 5 +
        n1 * 6;
      d2 = 11 - (d2 % 11);
      if (d2 >= 10) d2 = 0;

      if (this.format)
        return (
          "" +
          n1 +
          n2 +
          "." +
          n3 +
          n4 +
          n5 +
          "." +
          n6 +
          n7 +
          n8 +
          "/" +
          n9 +
          n10 +
          n11 +
          n12 +
          "-" +
          d1 +
          d2
        );
      else
        return (
          "" +
          n1 +
          n2 +
          n3 +
          n4 +
          n5 +
          n6 +
          n7 +
          n8 +
          n9 +
          n10 +
          n11 +
          n12 +
          d1 +
          d2
        );
    },

    gerarCPF(): string {
      const n = 9;
      let n1 = this.getRandomInt(0, n);
      let n2 = this.getRandomInt(0, n);
      let n3 = this.getRandomInt(0, n);
      let n4 = this.getRandomInt(0, n);
      let n5 = this.getRandomInt(0, n);
      let n6 = this.getRandomInt(0, n);
      let n7 = this.getRandomInt(0, n);
      let n8 = this.getRandomInt(0, n);
      let n9 = this.getRandomInt(0, n);
      let d1 =
        n9 * 2 +
        n8 * 3 +
        n7 * 4 +
        n6 * 5 +
        n5 * 6 +
        n4 * 7 +
        n3 * 8 +
        n2 * 9 +
        n1 * 10;
      d1 = 11 - (d1 % 11);
      if (d1 >= 10) d1 = 0;

      let d2 =
        d1 * 2 +
        n9 * 3 +
        n8 * 4 +
        n7 * 5 +
        n6 * 6 +
        n5 * 7 +
        n4 * 8 +
        n3 * 9 +
        n2 * 10 +
        n1 * 11;
      d2 = 11 - (d2 % 11);
      if (d2 >= 10) d2 = 0;

      if (this.format)
        return (
          "" +
          n1 +
          n2 +
          n3 +
          "." +
          n4 +
          n5 +
          n6 +
          "." +
          n7 +
          n8 +
          n9 +
          "-" +
          d1 +
          d2
        );
      else return "" + n1 + n2 + n3 + n4 + n5 + n6 + n7 + n8 + n9 + d1 + d2;
    },

    copiarTexto() {
      // Selecionar o conteúdo do input
      let texto = document.getElementById("textoInput") as HTMLInputElement;
      if (texto) texto.select();
      // Copiar o texto selecionado para a área de transferência
      document.execCommand("copy");
      // Alerta para informar que o texto foi copiado
      this.copy = true;
      setTimeout(() => {
        this.copy = false;
      }, 3000);
    },

    getRandomInt(min: number, max: number): number {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min; // O máximo é inclusivo e o mínimo é inclusivo
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  max-width: 600px;
}

.card {
  background-color: #ffffff;
}

.btn {
  transition: all 0.3s ease;
}

.btn:hover {
  transform: scale(1.05);
}

.alert {
  transition: opacity 0.5s;
}

.input-group input {
  font-size: 1.2rem;
}

.input-group button {
  background-color: #0d6efd;
  color: white;
  border: none;
}

.form-select,
.form-check-label {
  font-size: 1rem;
}
</style>
